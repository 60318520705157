import {RouteInfo} from './vertical-menu.metadata';


export const ROUTES_ADMIN: RouteInfo[] = [
    {
        path: '/company/list',
        title: 'Clientes',
        icon: 'ft-briefcase',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '',
        title: 'Adm Clientes',
        icon: 'ft-briefcase',
        class: 'has-sub',
        badgeClass: '',
        badge: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/companygroup/list',
                title: 'Grupos de Clientes',
                icon: 'ft-briefcase',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/userb/listcustomers',
                title: 'Usuários Clientes',
                icon: 'ft-briefcase',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
        ]
    },
    {
        path: '/supplier/list',
        title: 'Fornecedores',
        icon: 'ft-truck',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []

    },
    {
        path: '',
        title: 'Usuários Arktec',
        icon: 'ft-user',
        class: 'has-sub',
        badgeClass: '',
        badge: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/userb/list',
                title: 'Usuários',
                icon: 'ft-user',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/userbgroup/list',
                title: 'Grupos de Usuários',
                icon: 'ft-users',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            }
        ]
    },
];

export const ROUTES_OPER: RouteInfo[] = [
    {
        path: '/page', title: 'Workspace', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    }
];
