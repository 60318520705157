import {Injectable} from '@angular/core';

import {UserB} from './user-b';
import {HttpClient} from '@angular/common/http';
import {StorageUtils} from '../../../utils/storage-utils';
import {RestService} from '../../../utils/rest-service';
import {JsonContainer} from '../../../utils/json-container';
import {Role} from '../backoffice-role/role';
import {UserBSearch} from './user-b-search';


@Injectable()
export class UserBService extends RestService {

    constructor(private client: HttpClient, private storageUtils: StorageUtils) {
        super(client, 'userBackoffice', storageUtils);
    }

    authenticate(userB: UserB, callback: (result: JsonContainer, user: UserB) => void) {
        this.executePost('authenticate', userB, callback)
    }

    forgotPassword(userB: UserB, callback: (result: JsonContainer, user: UserB) => void) {
        this.executePut('forgotPassword/' + userB.email, null, callback)
    }


    menu(callback: (result: JsonContainer, roles: Role[]) => void) {
        this.executeGet('menu', callback)
    }


    search(userbSearch: UserBSearch, callback: (result: JsonContainer, list: any) => void) {
        this.executePost('search', userbSearch, callback)
    }

    autoLogin(userB: UserB, callback: (result: JsonContainer, user: UserB) => void) {
        this.executePost('autoLogin', userB, callback)
    }

    userBSession(callback: (result: JsonContainer, user: UserB) => void) {
        this.executeGet('userBSession', callback)
    }

    saveInfo(user: UserB, callback: (result: JsonContainer, user: UserB) => void) {
        this.executePost('saveInfo', user, callback)
    }
}
