import {Component, OnInit, ElementRef, Renderer2, OnDestroy, ViewChild} from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import {ConfigService} from '../services/config.service';
import {CustomizerService} from '../services/customizer.service';
import {UserBService} from '../../pages/ok-admin/user-b/user-b-service';
import {StorageUtils} from '../../utils/storage-utils';
import {UserB} from '../../pages/ok-admin/user-b/user-b';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {

  layoutSub: Subscription;
  isOpen = false;
  public config: any = {};
  user: UserB

  @ViewChild('darkMode', { static: false })
  darkModeField: ElementRef


  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              public customizerService: CustomizerService,
              private userBService: UserBService,
              private storageUtils: StorageUtils) {

    this.config = this.configService.templateConf;

    this.layoutSub = layoutService.toggleNotiSidebar$.subscribe(
        open => {
          this.isOpen = open;
        });
  }

  ngOnInit() {
    this.user = this.storageUtils.getLoggedUser()
    if (this.user.info && this.user.info['darkMode']) {
      this.config.layout.variant = 'Dark'
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  onClose() {
    this.layoutService.toggleNotificationSidebar(false);
  }

  changeTheme() {
    if (!this.user.info) {
      this.user.info = {
        groups: []
      }
    }
    if (this.config.layout.variant === 'Light') {
      this.customizerService.switchLayout('dark', false)
      this.user.info['darkMode'] = true
    } else if (this.config.layout.variant === 'Dark') {
      this.customizerService.switchLayout('light', false)
      this.user.info['darkMode'] = false
    }
    this.userBService.saveInfo(this.user, null)
    this.storageUtils.storeLoggedUser(this.user)
  }

  changeValue(event, field) {
    this.user.info[field] = event.target.checked
    this.userBService.saveInfo(this.user, null)
    this.storageUtils.storeLoggedUser(this.user)
  }
}
