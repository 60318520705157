<!-- START Notification Sidebar-->
<aside class="notification-sidebar d-none d-sm-none d-md-block" id="notification-sidebar" [ngClass]="{open: isOpen}">
  <a class="notification-sidebar-close" (click)="onClose()">
    <i class="ft-x font-medium-3 grey darken-1"></i>
  </a>
  <div class="side-nav notification-sidebar-content" [perfectScrollbar]>
    <div class="row">
      <div class="col-12 notification-tab-content">
        <nav ngbNav #nav="ngbNav" class="nav-tabs">
          <ng-container ngbNavItem class="nav-item">
            <a ngbNavLink class="nav-link">Atividades</a>
            <ng-template ngbNavContent>
              <div class="row tab-pane" id="activity-tab" role="tabpanel" aria-expanded="true"
                   aria-labelledby="base-tab1">
                <div class="col-12" id="activity">
                  <h5 class="my-2 text-bold-500">Últimas Atividades</h5>
                  <div class="timeline-left timeline-wrapper mb-3" id="timeline-1">
                    <app-list-activity-logs-page></app-list-activity-logs-page>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem class="nav-item">
            <a ngbNavLink class="nav-link">Configurações</a>
            <ng-template ngbNavContent>
              <div class="row tab-pane" id="settings-tab" aria-labelledby="base-tab2">
                <div class="col-12" id="settings">
                  <h5 class="mt-2 mb-3">General Settings</h5>
                  <ul class="list-unstyled mb-0 mx-2">
                    <li class="mb-3">
                      <div class="mb-1">
                        <span class="text-bold-500">Notificações</span>
                        <div class="float-right">
                          <div class="custom-switch">
                            <input class="custom-control-input" id="noti-s-switch-1" type="checkbox" (change)="changeValue($event, 'sendNotifications')" [checked]="this.user.info && this.user.info['sendNotifications'] == true">
                            <label class="custom-control-label" for="noti-s-switch-1"></label>
                          </div>
                        </div>
                      </div>
                      <p class="font-small-3 m-0">Habilite o envio de notificações de sua conta</p>
                    </li>
                    <li class="mb-3">
                      <div class="mb-1">
                        <span class="text-bold-500">Modo Dark</span>
                        <div class="float-right">
                          <div class="custom-switch">
                            <input class="custom-control-input" id="dark-switch" type="checkbox" (change)="changeTheme()" [checked]="this.user.info && this.user.info['darkMode'] == true">
                            <label class="custom-control-label" for="dark-switch"></label>
                          </div>
                        </div>
                      </div>
                      <p class="font-small-3 m-0">Habilite o modo dark no OrangeBox.</p>
                    </li>
                    <li class="mb-3">
                      <div class="mb-1"><span class="text-bold-500">Mostrar Atividades Recentes</span>
                        <div class="float-right">
                          <div class="checkbox">
                            <input id="noti-s-checkbox-1" type="checkbox" (change)="changeValue($event, 'showActivities')" [checked]="this.user.info && this.user.info['showActivities'] == true">
                            <label for="noti-s-checkbox-1"></label>
                          </div>
                        </div>
                      </div>
                      <p class="font-small-3 m-0">Habilite a visualização de atividades dentro de sua conta</p>
                    </li>
                    <li class="mb-3">
                      <div class="mb-1"><span class="text-bold-500">Novidades</span>
                        <div class="float-right">
                          <div class="custom-switch">
                            <input class="custom-control-input" id="noti-s-switch-4" type="checkbox" (change)="changeValue($event, 'showNews')" [checked]="this.user.info && this.user.info['showNews'] == true">
                            <label class="custom-control-label" for="noti-s-switch-4"></label>
                          </div>
                        </div>
                      </div>
                      <p class="font-small-3 m-0">Mantenha-me atualizado sobre as novidades do OrangeBox</p>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</aside>
<!-- END Notification Sidebar-->
