import {HttpClient, HttpHeaders} from '@angular/common/http';
import {JsonContainer} from './json-container';
import {StorageUtils} from './storage-utils';
import {environment} from '../../environments/environment';


export class RestService {

    constructor(private http: HttpClient, protected service: string, private storage: StorageUtils) {
    }

    executePost(urlSufix: string, data: any, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.post(url, data, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                if (callback) {
                    callback(jc, null)
                }
            })
    }


    executePostVideo(urlSufix: string, data: any, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptionsVideo()
        const jc = new JsonContainer()

        this.http.post(url, data, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                callback(jc, null)
            })
    }

    executePostCheck(urlSufix: string, data: any, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptionsVideo()
        const jc = new JsonContainer()

        this.http.post(url, data, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                callback(jc, null)
            })
    }

    executePut(urlSufix: string, data: any, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.put(url, data, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                callback(jc, null)
            })
    }


    executePostCustom(urlSufix: string, data: any) {
        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        return this.http.post(url, data, options);
    }

    executeGet(urlSufix: string, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.get(url, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                callback(jc, null)
            })
    }

    executeGetReport(urlSufix: string, callback: (data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptionsReport();
        let jc: any

        this.http.get(url, options).subscribe(resp => {
                    jc = resp
                    callback(jc)
            },
            error => {
                jc = null
                callback(jc)

            })
    }


    executeDelete(urlSufix: string, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.delete(url, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                callback(jc, null)
            })
    }

    executeGetUrl(url: string, callback: (result: JsonContainer, data: any) => void): void {

        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.get(url, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                callback(jc, null)
            })
    }

    private httpOptions(): {} {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.storage.getToken(),
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, Access-Control-Allow-Headers, Accept, Authorization, X-Requested-With',
                'Access-Control-Allow-Credentials': 'true',
                'Accept': 'application/json'
            })
        };
    }

    private httpOptionsVideo(): {} {
        return {
            headers: new HttpHeaders({
                'Accept': 'application/json'
            })
        };
    }

    private httpOptionsReport(): {} {
        return {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.storage.getToken(),
            }),
            'responseType': 'text'
        };
    }

    getUrl(urlSufix: string): string {
        return environment.baseUrl + '/' + this.service + '/' + urlSufix;
    }
}
