import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';

import {Full_ROUTES} from './shared/routes/full-layout.routes';
import {CONTENT_ROUTES} from './shared/routes/content-layout.routes';

import {AuthGuard} from './shared/auth/auth-guard.service';
import {LoadingPageComponent} from './pages/loading-page/loading-page.component';
import {CheckTokenPageComponent} from './pages/check-token/check-token-page.component';

const appRoutes: Routes = [
  {path: 'check', component: CheckTokenPageComponent},
  {path: '', component: LoadingPageComponent, canActivate: [AuthGuard]},
  {path: '', component: FullLayoutComponent, data: {title: 'full Views'}, children: Full_ROUTES, canActivate: [AuthGuard]},
  {path: '', component: ContentLayoutComponent, data: {title: 'content Views'}, children: CONTENT_ROUTES},
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
