import {UserB} from '../pages/ok-admin/user-b/user-b';

export class StorageUtils {

  public storeToken(token: string) {
    localStorage.setItem('token', token);
  }

  public getToken() {
    return localStorage.token
  }

  public idUser(idUser: string) {
    localStorage.setItem('idUser', idUser);
  }

  public getIdUser() {
    return localStorage.idUser
  }

  public nameUser(nameUser: string) {
    localStorage.setItem('nameUser', nameUser);
  }

  public getNameUser() {
    return localStorage.nameUser
  }

  public idWorkspace(idWorkspace: string) {
    localStorage.setItem('idWorkspace', idWorkspace);
  }

  public getIdWorkspace() {
    return localStorage.idWorkspace
  }

  public urlHome(urlHome: string) {
    localStorage.setItem('urlHome', urlHome);
  }

  public getUrlHome() {
    return localStorage.urlHome
  }

  public idEvent(idEvent: string) {
    localStorage.setItem('idEvent', idEvent);
  }

  public getIdEvent() {
    return localStorage.idEvent
  }

  public idCompany(idCompany: string) {
    localStorage.setItem('idCompany', idCompany);
  }

  public getIdCompany() {
    return localStorage.idCompany
  }

  public roleId(roleId: string) {
    localStorage.setItem('roleId', roleId);
  }

  public getRoleId() {
    return localStorage.roleId
  }

  public urlImage(urlImage: string) {
    localStorage.setItem('urlImage', urlImage);
  }

  public getUrlImage() {
    return localStorage.urlImage
  }

  public storeAutoLogin(user: UserB) {
    if (user == null) {
      localStorage.setItem('autoLogin', null);
    } else {
      localStorage.setItem('autoLogin', JSON.stringify(user));
    }
  }

  public getAutoLogin(): UserB {
    if (localStorage.autoLogin == null) {
      return null;
    }
    return JSON.parse(localStorage.autoLogin);
  }

  public getRedirectUrl() {
    return localStorage.redirectUrl
  }

  public redirectUrl(redirectUrl: string) {
    localStorage.setItem('redirectUrl', redirectUrl);
  }

  public storeLoggedUser(loggedUser: UserB) {
    if (loggedUser == null) {
      localStorage.setItem('loggedUser', null);
    } else {
      localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
    }
  }

  public getLoggedUser(): UserB {
    if (localStorage.loggedUser == null) {
      return null;
    }
    return JSON.parse(localStorage.loggedUser);
  }
}
